import Button from 'react-bootstrap/Button';
import classes from './style/Quiz.module.css';
import {useEffect, useState} from 'react';
import UserQuizResult from './components/UserQuizResult';
import {getQuizData, submitQuiz} from './Quiz.services';
import {useLocation, useParams} from 'react-router';
import {Col, Container, Row} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import {Report} from './interface/IQuiz';
import {CourseType} from '../Courses/interfaces/ICourses';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {getCourseDetails} from '../Courses/Courses.service';
import {useNavigate} from 'react-router-dom';
import {updateProgressAutomatic} from '../Tracking/Trackings.service';
import {Modal} from 'react-bootstrap';
import CancelButton from '../../UI/cancel-button/CancelButton';

const QuizTrack = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const questions = location.state.questions;
  const mediaId = location.state.mediaId;
  const userActivityId = location.state.userActivityId;
  const initiative = location.state.initiative;

  console.log('questions', questions);

  let {id} = useParams();
  const [item, setItem] = useState<any>();
  const [activeQuestion, setActiveQuestion] = useState<number>(-1);
  const [selectedAnswer, setSelectedAnswer] = useState<boolean>(false);
  const [goToChart, setGoToChart] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showRightAnswers, setShowRightAnswers] = useState<boolean>(false);
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [report, setReport] = useState<Report | undefined>();
  const [answeredSeelcted, setAnsweredSeelcted] = useState<boolean>(false);
  const [ansewredQuetionLength, setAnsewredQuetionLength] = useState<any>([]);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    getQuiz();
  }, []);

  const getQuiz = async () => {
    //let result = await getQuizData(id);
    //if (result.response) {
    setItem(questions);
    //}
    //setLoading(false);
  };
  const onClickPrev = () => {
    
    
    if (activeQuestion >= item?.questions?.length - 1) {
      setActiveQuestion(prev => prev - 1);
    }
  };
  const onClickNext = () => {
    setAnsweredSeelcted(false)
    setSelectedAnswerIndex(null);
    setResult(prev =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + 5,
            correctAnswers: prev.correctAnswers + 1,
          }
        : {...prev, wrongAnswers: prev.wrongAnswers + 1},
    );
    if (activeQuestion !== item?.questions?.length - 1) {
      setActiveQuestion(prev => prev + 1);
    } else {
      setShow(true)
    }
  };

  const onAnswerSelected = (answer: any, index: any) => {
    setAnsweredSeelcted(true)
    let quiz = {...item};
    quiz?.questions[activeQuestion]?.answers.map((o: any, p: any) => {
      if (p == index) {
        if (!o.selected && item?.questions[activeQuestion].type == 'select') {
          o.selected = true;

          setCorrectAnswers(correctAnswers + 1);
        } else if (
          o.selected == true &&
          item?.questions[activeQuestion].type == 'select'
        ) {
          o.selected = false;
          setCorrectAnswers(correctAnswers - 1);
        } else {
          o.selected = true;
        }
      } else if (item?.questions[activeQuestion].type != 'select')
        o.selected = false;
    });
    setItem(quiz);
    setSelectedAnswerIndex(index);
    if (answer.is_correct == 1) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };
  const updateAuto = async (progress: any) => {
    dispatch(setLoading(true));

    console.log(
      'result.response.answers_reportresult.response.answers_report',
      progress,
    );

    let data = new FormData();
    data.append('user_activity_id', String(userActivityId));
    data.append('progress', progress);
    data.append('media_id', mediaId);
    let result = await updateProgressAutomatic(data);

    if (result.response.status) {
      dispatch(setLoading(false));
      //navigate(-1)
      setGoToChart(true);
    }
  };
  const submit = async () => {
    dispatch(setLoading(true));
    let data = new FormData();
    item?.questions.map((i: any, key: any) => {
      data.append(`questions[${key}][question_id]`, i.id);
    });
    console.log('item/', item);

    item?.questions.map((i: any, key: any) => {
      i.answers.map((o: any, p: any) => {
        o.selected && data.append(`questions[${key}][answers_id][${p}]`, o.id);
      });
    });

    data.append(`quizze_id`, String(questions.id));
    
    data.append(`user_activity_id`, userActivityId);
    
    
    

    let result = await submitQuiz(data);

    if (result.response) {
      setShow(false)
      dispatch(setLoading(false));
      setReport(result.response.answers_report);
      if (activeQuestion === item?.questions?.length - 1) {
        updateAuto(result.response.answers_report.percentage);
      }
    }
  };

  const addLeadingZero = (number: number) =>
    number > 9 ? number : `0${number}`;
  return (
    <div className='quiz-container'>
      {activeQuestion == -1 ? (
        <section className={`section text-center ${classes.personalityTest}`}>
          <div className='p-2'>
            <h1 className='quiz-title' style={{ color: '#FF5659' }}>
              <img src="/flag.svg" alt="flag" className='mx-1' /> Are You Ready?  </h1>
            <p className='quiz-desc'>
            Let's learn more about the test
            </p>

            <ul className='quiz-notes d-flex justify-content-center flex-column align-items-center'>

              <li>
              The test consists of {item?.questions?.length} questions
              </li>

              
              { item?.questions.some((item:any) => item?.type === 'single' || item?.type === 'radio' ) ? 
              <li>
              Single choice qustions
              </li>
              : '' }

              { item?.questions.some((item:any) => item?.type === 'select' ) ?   
              <li>
              Multiple choice qustions
              </li>
              : '' }

             
            </ul>

            <Row className="mt-4">
              <Col
                md={3}
                className="m-auto mt-5"
                onClick={() => setActiveQuestion(0)}>
                <MainButton text="I'm ready! Start the test" />
              </Col>
            </Row>
          </div>
        </section>
      ) : !showResult ? (
        <div>
          <Container>
            
            <section className={`section ${classes.personalityTest}`}>
              { !showRightAnswers ?
              <div className='quiz-quetion-header d-flex align-items-center justify-content-center'>

                

                <svg 
                onClick={onClickPrev}
                style={{ width: '40px' ,height: '20px' }}
                xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none" >
                  <path d="M7 13L1 7L7 1" stroke="#8B8B8B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>


                <p className='my-0 mx-5' style={{ fontSize: '18px' }} >Question {activeQuestion + 1} <span className='reminder' style={{ color: '#3E3E3E70', fontSize: '15px' }} >From {item?.questions?.length} Questions</span></p>

                <svg 
                style={{ width: '40px' ,height: '20px' , transform: 'rotate(180deg)' }}
                onClick={onClickNext}
                xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                  <path d="M7 13L1 7L7 1" stroke="#8B8B8B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>



              </div>
              : ''  }

              <div className='text-center mt-5'>
                { !showRightAnswers ?
                <p className='choose-answer'>
                { 
                item?.questions[activeQuestion]?.type == 'single' || item?.questions[activeQuestion]?.type == 'radio' ? 'Please choose one answer' : 'Please choose one or more than one answer' 
                }
                </p>
                : ''  }
                <h3 className="quiz-quetion mb-5">
                  {item?.questions[activeQuestion]?.question}
                </h3>

              </div>

              <div>
                
              </div>

              <div className={classes.Questions}>
                { (item?.questions[activeQuestion]?.type == 'single' || item?.questions[activeQuestion]?.type == 'radio' ) && item?.questions[activeQuestion]?.answers?.map(
                  (answer: any, index: any) => (
                    
                     <p
                     
                      
                      key={`${answer.id} ${index}`}
                      className={`${classes.Question} position-relative`}>
                      <input
                        checked={item?.questions[activeQuestion]?.answers[index].selected}
                        type="radio"
                        id={`answer-${index}`}
                        name="radio-group"
                        onChange={() => {
                          if( !ansewredQuetionLength.includes(item?.questions[activeQuestion]?.id) ){
                            ansewredQuetionLength.push(item?.questions[activeQuestion]?.id)
                          }
                          onAnswerSelected(answer, index)
                        }}
                        disabled={showRightAnswers}
                      />
                      <label
                      
                      htmlFor={`answer-${index}`} className={`${ showRightAnswers && (item?.questions[activeQuestion]?.answers[index].is_correct ? 'final-correct' : 'final-wrong')}`}>
                        {answer?.answer}


                      </label>
                      {showRightAnswers ? 
                      <div className='text-right mt-1'>
                        { item?.questions[activeQuestion]?.answers[index].is_correct ? 
                        <img src="correct-answer.svg" alt="img" /> 
                        :
                        <img src="wrong-answer.svg" alt="img" />
                        }
                      </div>
                      : '' }
                    </p>
                    
                  ),
                )}

                { item?.questions[activeQuestion]?.type == 'select' && item?.questions[activeQuestion]?.answers?.map(
                  (answer: any, index: any) => (     
                     <p
                      
                      key={`${answer.id} ${index}`}
                      className={`${classes.Question} position-relative`}>
                      <input
                        checked={item?.questions[activeQuestion]?.answers[index].selected}
                        type="checkbox"
                        id={`answer-${index}`}
                        name="check-group"
                        onChange={() => {
                          if( !ansewredQuetionLength.includes(item?.questions[activeQuestion]?.id) ){
                            ansewredQuetionLength.push(item?.questions[activeQuestion]?.id)
                          }
                          onAnswerSelected(answer, index)
                          
                        }}
                        disabled={showRightAnswers}
                      />
                      <label 
                        
                      htmlFor={`answer-${index}`} className={`${ showRightAnswers && (item?.questions[activeQuestion]?.answers[index].is_correct ? 'final-correct' : 'final-wrong')}`}>
                        {answer?.answer}
                      </label>
                      {showRightAnswers ? 
                      <div className='text-right mt-1'>
                        { item?.questions[activeQuestion]?.answers[index].is_correct  ? 
                        <img src="correct-answer.svg" alt="img" /> 
                        :
                        <img src="wrong-answer.svg" alt="img" />
                        }
                      </div>
                      : '' }
                    </p>
 
                  ),
                )}

                { showRightAnswers ? 
                <div>
                  { activeQuestion !== item?.questions?.length - 1 ? 
                  <div className='mt-1'
                  onClick={() => {
                    if (activeQuestion !== item?.questions?.length - 1) {
                      setActiveQuestion(prev => prev + 1);
                    }
                  } }
                  >
                    <MainButton text={'Show the answer to the following question'} />
                  </div>
                  : ''  }

                  <div className="w-100 mt-3"
                  style={{
                    color: '#9B9B9B',
                    fontSize: '15px',
                    fontWeight: '700',
                    cursor: 'pointer',
                    textAlign: 'center'
                  }}
                  onClick={() => navigate(-1) } >
                    Return to the activities page
                  </div>

                </div>
                :
                
                <Button
                  onClick={onClickNext}
                  disabled={answeredSeelcted == false && ( activeQuestion !== item?.questions?.length - 1 ) }
                  className={classes.NextButton}
                  variant="primary">
                  {activeQuestion === item?.questions?.length - 1
                    ? 'Finish'
                    : 'Continue'}
                </Button>
                }

              </div>

              
            </section>
          </Container>
        </div>
      ) : null}

      {showResult && goToChart && (
        <Col md={{span: 4, offset: 4}}>
          
        <div className='text-center mb-5'>
          <span className="d-none">{result.score}</span>
          {/*
          <UserQuizResult report={report} title={item.title} />
          */}
          { ( report?.percentage ? report.percentage : 0 ) >= 50 ? <img src="/sucess.svg" alt="img" className='my-3' /> : <img src="/sad.png" alt="img" className='my-3' /> }

          { ( report?.percentage ? report.percentage : 0 ) >= 50 ? 
          <div>
            <p className='quiz-popup-title'>Congratulations! You passed the test</p>
            <p className='quiz-popup-desc mb-0 mx-5'>
            Congratulations, you passed this test
            </p>
            <p className='quiz-success-title'>
            You got a success rate of {report?.percentage}%
            </p>
          </div>  
          :
          <div>
            <p className='quiz-popup-title'>You have no luck achieving success</p>
            <p className='quiz-popup-desc mb-0 mx-5'>
            Unfortunately, the success rate was not exceeded
            </p>
            <p className='quiz-fail-title'>
            You got a success rate of {report?.percentage}%
            </p>
          </div>
          }
          

          <div className="w-100"
            onClick={ () => {
              console.log('show my answers' , item?.questions )
              setShowResult(false)
              setGoToChart(false)
              setShowRightAnswers(true)
            } }
            >
              <MainButton text={'Show Answers'} />
            </div>

            <div className="w-100 mt-3" onClick={() => navigate(-1) } >
              <CancelButton text={'Return to the activities page'} />
            </div>
        </div>
        </Col>
      )}


        {/*Confirm Submit*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>

          <Modal.Body className='text-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="93" height="92" viewBox="0 0 93 92" fill="none">
            <path d="M46.5 5.75C57.175 5.75 67.4127 9.99061 74.961 17.539C82.5094 25.0873 86.75 35.325 86.75 46C86.75 56.675 82.5094 66.9127 74.961 74.461C67.4127 82.0094 57.175 86.25 46.5 86.25C35.825 86.25 25.5873 82.0094 18.039 74.461C10.4906 66.9127 6.25 56.675 6.25 46C6.25 35.325 10.4906 25.0873 18.039 17.539C25.5873 9.99061 35.825 5.75 46.5 5.75ZM46.5 80.5C55.65 80.5 64.4252 76.8652 70.8952 70.3952C77.3652 63.9252 81 55.15 81 46C81 36.85 77.3652 28.0748 70.8952 21.6048C64.4252 15.1348 55.65 11.5 46.5 11.5C37.35 11.5 28.5748 15.1348 22.1048 21.6048C15.6348 28.0748 12 36.85 12 46C12 55.15 15.6348 63.9252 22.1048 70.3952C28.5748 76.8652 37.35 80.5 46.5 80.5ZM50.8125 64.6875C50.8125 65.8312 50.3581 66.9281 49.5494 67.7369C48.7406 68.5456 47.6437 69 46.5 69C45.3563 69 44.2594 68.5456 43.4506 67.7369C42.6418 66.9281 42.1875 65.8312 42.1875 64.6875C42.1875 63.5438 42.6418 62.4469 43.4506 61.6381C44.2594 60.8294 45.3563 60.375 46.5 60.375C47.6437 60.375 48.7406 60.8294 49.5494 61.6381C50.3581 62.4469 50.8125 63.5438 50.8125 64.6875ZM46.5 23C47.2625 23 47.9938 23.3029 48.5329 23.8421C49.0721 24.3812 49.375 25.1125 49.375 25.875V51.75C49.375 52.5125 49.0721 53.2438 48.5329 53.7829C47.9938 54.3221 47.2625 54.625 46.5 54.625C45.7375 54.625 45.0062 54.3221 44.4671 53.7829C43.9279 53.2438 43.625 52.5125 43.625 51.75V25.875C43.625 25.1125 43.9279 24.3812 44.4671 23.8421C45.0062 23.3029 45.7375 23 46.5 23Z" fill="#FF5659"/>
          </svg>
          <p className='quiz-popup-title mt-3'>
          

          { (ansewredQuetionLength.length == item?.questions?.length) ? 'Are you sure you want the answers?' : 'You did not answer all the questions'  }
          
          </p>
          <p className='quiz-popup-desc mx-5'>
          { (ansewredQuetionLength.length == item?.questions?.length) ? 'You have answered all the questions. Do you want to confirm and get grades?' : 'You have not answered all the test questions. Do you want to confirm and approve the current status?'  }  
          
          </p>
          </Modal.Body>

          <Modal.Footer>

            { (ansewredQuetionLength.length == item?.questions?.length) ? 
            <div className="w-100">
              <div className="w-100"
              onClick={ () => {
                setActiveQuestion(0);
                setShowResult(true);
                submit();
              } }
              >
                <MainButton text={'Confirm the answers'} />
              </div>

              
              <div className="w-100 mt-2" onClick={() => { setShow(false) } }>
                <CancelButton text={'I want to return to complete the solution of the questions'} />
              </div>
            </div>

            :
            
            <div className="w-100">
              <div className="w-100"
              onClick={ () => {
                setActiveQuestion(1);
                setShowResult(false);
                setShow(false)
              } }
              >
                <MainButton text={'I want to continue solving the remaining questions'} />
              </div>

              
              <div className="w-100 mt-2" onClick={() => {
              setActiveQuestion(0);
              setShowResult(true);
              submit();
              } }>
                <CancelButton text={'I want to confirm and approve the current situation'} />
              </div>
            </div>
            }
            

          </Modal.Footer>
      </Modal>
    </div>

    
  );
};

export default QuizTrack;
