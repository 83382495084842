import {Card, Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {FaCircle, FaEdit, FaFile, FaFileAlt, FaFileArchive, FaFileCode, FaFileSignature, FaFileUpload, FaPaperclip, FaPlay, FaPlaystation, FaRegCheckSquare, FaRegEdit, FaRegFileAlt, FaYoutube, FaYoutubeSquare} from 'react-icons/fa';
import { IoGameControllerOutline } from "react-icons/io5";

import practicalTaskClasses from '../Courses/style/PracticalTaskUplaod.module.css';
import {NavLink} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  addTrackNote,
  editTrackNote,
  removeActivityProof,
  sendInitiativeProf,
  updateProgress,
  updateProgressAutomatic,
} from '../Tracking/Trackings.service';
import { useAppDispatch , useAppSelector } from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';

import {getActivityData} from './Initiatives.service';

import moment from 'moment';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import YouTube from 'react-youtube';
import InitiativeDetailsCard from './InitiativeDetailsCard';
import toast, {Toaster} from 'react-hot-toast';
import { getDomain } from '../../helpers/getDomain';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import { colors } from '../../constants/colors';
import ZeroProgressPopover from './components/ZeroProgressPopover';
import { t } from 'i18next';
import Form from 'react-bootstrap/Form';
import ProofItem from './components/ProofItem';
import ReactQuill from 'react-quill';


const UserActvitiyAddProgress = () => {
  const videoOptions = {
    playerVars: {
      // You can add additional YouTube player options here
      autoplay: 0,
    },
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [lang, setLang] = useState(queryParams.get('lang') || 'en');
  const trackType = useState<any>(queryParams.get('trackType') || null);
  
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  
  const [progressVal, setProgressVal] = useState<any>(1);
  const [showProgressVal, setShowProgressVal] = useState<any>(false);

  const [showGame, setShowGame] = useState<boolean>(false);

  const [confirmUpdate, setConfirmUpdate] = useState<boolean>(false);

  const [showAddNote, setShowAddNote] = useState<boolean>(false);

  const [confirmDeleteNote, setConfirmDeleteNote] = useState<boolean>(false);

  const [showAllNotes, setShowAllNotes] = useState<boolean>(false);

  const [allNote, setAllNote] = useState<any>([]);
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {actId, trackId, date, userId , daySelected } = useParams();

  const [activityData, setActivityData] = useState<any>();

  const [selectedNoteId, setSelectedNoteId] = useState<any>();

  const [selectedNoteValue, setSelectedNoteIdValue] = useState<any>();

  const [profMidea, setprofMidea] = useState<any>();
  
  
  //const daySelected = location.state.daySelected;
  const today = moment().locale('en').format('YYYY-MM-DD');

  const [userActivityId, setUserActivityId] = useState(null);

  const [showUpdateProgress, setShowUpdateProgress] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [styleProgress, setStyleProgress] = useState<number>(0);

  const [autoUpdate, setAutoUpdate] = useState<number>(1);

  const [showYoutubeTask, setShowYoutubeTask] = useState<boolean>(false);

  const [showGameEndMsg, setshowGameEndMsg] = useState<boolean>(false);

  const [activeGameId, setActiveGameId] = useState<any>(null);

  const [activeGameMedia, setActiveGameMedia] = useState<any>(null);

  const [activeSocialId, setActiveSocialId] = useState<any>(null);

  const [activeSocialMedia, setActiveSocialMedia] = useState<any>(null);

  const [linkName, setLinkName] = useState<any>(null);

  


  const hasHistory = queryParams.has('tracking_enrollment_history_id');

  const [proofId, setProofId] = useState<any>(null);
  
  const [proofType, setProofType] = useState<any>('link');
  

  const getData = async (showPopup=0) => {
    dispatch(setLoading(true));
    let trackHistory = hasHistory ? queryParams.get('tracking_enrollment_history_id') : null
    let result = await getActivityData(actId!, daySelected!, userId!, trackId!,trackHistory);
    if (result.response) {
      dispatch(setLoading(false));
      console.log('progress data', result.response.data.media);
      setActivityData(result.response.data);
      setprofMidea(
        result.response.data?.media?.profs.length
          ? result.response.data?.media?.profs[0].media_path
          : null,
      );
      setAutoUpdate(result.response.data.auto_progress_update);
      if( result.response.data.auto_progress_update == 0 && result.response.data.progress){
        setProgress(result.response.data.progress)
      }

      
      const checkAllAnswered = Object.entries(result.response.data.media)
      .flatMap(([, value]) => value)
      .every((item:any) => item.is_answered === 1);
      
      if( checkAllAnswered && showPopup == 1 && !moment(daySelected).isSame(result.response.data.first_day) && result.response.data.date_type == 'date_range' ){
        setShowDateRangeMsg(true)
      }

    }
  };

  const getAllNote = async () => {
    dispatch(setLoading(true));
    let token = localStorage.getItem('token');
    
    let url = `/api/trackingsystem/note/all-activity-notes?activity_id=${actId}&current_user_id=${userId}&tracking_id=${trackId}`
   
    try {
      let result = await axios.get(getDomain() + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('dsadsadafdata', result.data.data);

      if (result.data.status) {
      dispatch(setLoading(false));
       setAllNote(result.data.data)
       setShowAllNotes(true)
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  const deleteNote = async (noteId: any) => {
    let token = localStorage.getItem('token');
    try {
      let result = await axios.delete(
        `${getDomain()}/api/trackingsystem/note/${noteId}`,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (result.data.status) {
        setConfirmDeleteNote(false);
        setShowAllNotes(false)
        getData();
      }
    } catch (e) {}
  };

  const addNote = async (actId: any) => {
    let data = new FormData();
    data.append(`note`, selectedNoteValue);
    data.append(`user_tracking_activity_id`, actId);

    console.log(data);
    let result = await addTrackNote(data);

    if (result.response.status) {
      setShowAddNote(false);
      getData();
    }
  };

  const editNote = async () => {
    let data = new FormData();
    data.append('note', selectedNoteValue);
    data.append('id', selectedNoteId);
    let result = await editTrackNote(data);

    if (result.response.status) {
      setShow(false);
      setShowAllNotes(false)
      getData();
    }
  };

  const updateAuto = async (id: any, progress: any) => {
    let data = new FormData();
    data.append('user_activity_id', activityData.user_activity_id);
    data.append('progress', progress);
    data.append('media_id', id);
    let result = await updateProgressAutomatic(data);

    if (result.response.status) {
      if( result.response.data == '100' && activityData?.date_type == 'date_range' && trackType != null ){
        setShowDateRangeMsg(true)
      }
      getData(1);
    }
  };

  const handleClick = (e: any) => {
    const bar = e.currentTarget;

    const clickPosition = e.clientX - bar.getBoundingClientRect().left;

    const totalWidth = bar.clientWidth;
    let newProgress = (clickPosition / totalWidth) * 100;
    if (newProgress >= 95) {
      newProgress = 100;
    }
    setProgress(Math.floor(newProgress));
    if (newProgress >= 90) {
      setStyleProgress(85);
    } else {
      setStyleProgress(Math.floor(newProgress));
    }
  };

  const updateManualProgress = async () => {
    let data = new FormData();
    data.append('user_activity_id', String(userActivityId));
    data.append('progress', String(Math.floor(progress)));
    let result = await updateProgress(data);

    if (result.response.status) {
      setShowUpdateProgress(false);
      if( result.response.data == '100' && activityData?.date_type == 'date_range' && trackType != null ){
        setShowDateRangeMsg(true)
      }
      getData(1)
    }
  };

  let token = localStorage.getItem('token');

  const [topSmallAds, setTopSmallAds] = useState([]);
  const [bottomSmallAds, setBottomSmallAds] = useState([]);

  const getSmallAds = async () => {
    let api = `${getDomain()}/api/mobile/get/small/ads/Activity/${actId}`;

    try {
      let result = await axios({
        url: api,
        method: 'get',

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('small ads', result?.data?.data);
      if(result?.data?.data.length){
        setTopSmallAds(result?.data?.data.filter((obj:any) => obj.position == 3))
        setBottomSmallAds(result?.data?.data.filter((obj:any) => obj.position == 4))
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    getSmallAds();
  }, []);

  const [showDateRangeMsg, setShowDateRangeMsg] = useState<boolean>(false);
  

  return (
    <div className={`mt-md-5 position-relative`}>
      <Toaster position="top-right" />
      <Container>
        <Row className="d-flex justify-content-between">
          {/* <Col xs={12} lg={4} className="mt-3 mb-3">
            <InitiativeDetailsCard tracking={item!} />
          </Col> */}

          <Col xs={12} lg={12} className="mt-3">
            
            <Card style={{padding: '1rem 2rem' ,borderColor: 'green' , marginBottom: '2rem' }}>
            <div
                className="d-flex justify-content-between align-items-center"
                >
                <div>
                  
                <h4>
                  
                  {activityData?.name}</h4>
                <div style={{fontSize: '14px'}} className={`${moment(activityData?.end_date).isSame(today) ? 'secondry-color' : '' } font-weight-bold`}>End Date: {moment(activityData?.end_date).isSame(today) ? 'Today' : activityData?.end_date}
                </div>

                { activityData?.date_type == 'date_range' && trackType != null  && !moment(daySelected).isSame(activityData?.first_day) ? 
                <div className='text-danger'>
                  This activity moved from day { activityData?.first_day }
                </div>
                : '' }
                </div>
                
                <div className='d-flex justify-content-center align-items-center'>
                {activityData?.is_completed == 1 && (
                      <FaRegCheckSquare
                        size={19}
                        color="green"
                        style={{verticalAlign: 'text-bottom' , marginInline: '35px' }}
                      />
                    )}
                  { !activityData?.no_score && <CircleProgress value={activityData?.progress ?? 0 } />}
                </div>
              </div>
              </Card>

            <div>
              {topSmallAds.length > 0 ? (
                <div className="mt-3 mb-3 cursor-pointer">
                  {topSmallAds.map((item:any) => (
                     <div
                     onClick={ () => {
                      if(item.model_name == 'Course' ){
                       
                        window.location.href = `/courses/${item.model_id}`
                      }else if(item.model_name == 'Consultant'){
                        window.location.href = `/consultants/organization/${item.model_id}`
                      }
                      else if( item.model_name == 'Tracking' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/tracking/${item.model_id}/details`
                        }
                      }
                      else if( item.model_name == 'TrackingPackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=1`
                        }
                      }
                      else if( item.model_name == 'Initiative' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/initiative/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/details?is_level=0`
                        }
                      }else if( item.model_name == 'InitiativePackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=1`
                        }
                      }
                    } }
                     ><img src={item.image} alt="img" className='w-100 rounded' /></div>
                  ))}
                </div>
              ) : (
              ''
              )}
            </div>

            <div className="mb-2">
              <h4>{t('About Activity')}</h4>
              {/*
              <p className={`text-font-muted ${ lang == 'ar' ? 'ar-dir' : '' }`}>{activityData?.description}</p>
              */}
              <ReactQuill theme="bubble" value={activityData?.description} readOnly={true}  />
            </div>

            {activityData?.intro_video ? (
              <div className="mb-5" style={{ cursor: 'pointer' }}>
                <h4>{t('Intro Video')}</h4>
                <div className="media mt-3">
                  <div className="d-flex justify-content-between flex-column mb-3">
                    <div>
                    <FaPlay
                            className="activity-icon"
                            style={{
                              color:colors.chamThirdColor ,
                              background:colors.lightred,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '15px',
                              
                            }}
                            />
                      
                    </div>

                    <video
                      width="320"
                      height="240"
                      controls
                      className="mt-4"
                      style={{borderRadius: '15px'}}>
                      <source
                        src={activityData?.intro_video}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {activityData?.auto_progress_update ? (
              <div>
                {activityData?.media?.attachments?.length > 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}>
                    <h4>{t('Attachments')}</h4>
                    <div className="attachments mt-3">
                      {activityData?.media?.attachments.map((a: any) => {
                        return (
                          <div className="d-flex justify-content-between align-items-center mb-3"
                          
                          >
                            <div
                            onClick={() => {
                              if (!moment(daySelected).isAfter(today) || moment(daySelected).isSame(today) ) {
                                
                                window.open(a.media_path, '_blank');
                                daySelected == today &&
                                  updateAuto(a.id, 100);
                              } else {
                                toast.error("Can't open today");
                              }
                            }}
                            className='d-flex'
                            >
                            <FaRegFileAlt 
                             className="activity-icon"
                            style={{
                              color:a.percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:a.percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '10px',
                              
                            }}
                            />
                              <div>
                                <span>{a.name}</span>
                                { a.submitted_at && <div className="secondry-color font-weight-bold submitted-at">Submitted At: {a.submitted_at} </div>}
                              </div>
                            </div>

                            <div>
                              <div className='d-flex align-items-center'>
                                {a.is_answered ? 
                                  <FaRegCheckSquare
                                    size={19}
                                    color="green"
                                    style={{
                                      verticalAlign: 'text-bottom',
                                      margin: '1.5rem',
                                    }}
                                  />
                                  :
                                  ''
                                }

                                  <div
                                    >
                                    <img
                                    onClick={ () =>  window.open(`${a.media_path}`,'_blank') }
                                      src="/icons/download.png"
                                      alt="image"
                                      style={{
                                        width: '50px',
                                        margin: '0 20px 0 0',
                                      }}
                                    />

                                        {a.percentage == 0 && ( <ZeroProgressPopover /> ) }
                                  </div>

                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {activityData?.media?.videos.length != 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}
                  onClick={ () => {
                    if( moment(daySelected).isAfter(today) ){
                      toast.error("Can't open today");
                    }
                  } }
                  >
                    <h4>{t('Media')}</h4>
                    <div className="media mt-3">
                      <div
                        className={`d-flex justify-content-between mb-3`}> 
                        <div>
                        <FaPlay
                           className="activity-icon"
                            style={{
                              color:activityData?.media?.videos[0].percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:activityData?.media?.videos[0].percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '15px',
                             
                            }}
                            />
                          <span>{activityData?.media?.videos[0].name}</span>
                          { activityData?.media?.videos[0].submitted_at && <div className="secondry-color font-weight-bold submitted-at">Submitted At: {activityData?.media?.videos[0].submitted_at} </div>}
                        </div>

                        <div className='d-flex align-items-center' >

                        

                        {activityData?.media?.videos[0].is_answered ? 
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        :
                        ''
                        }

                      {activityData?.media?.videos[0].percentage == 0 && ( <ZeroProgressPopover /> ) }

                      </div>

                        
                          
                          
                        
                      </div>

                      {
                        !moment(daySelected).isAfter(today) ? 
                        <video
                        width="320"
                        height="240"
                        controls
                        className="mt-4"
                        style={{borderRadius: '15px'}}
                        onEnded={() => {
                          daySelected == today &&
                            updateAuto(
                              activityData?.media?.videos[0].id,
                              100,
                            );
                        }}>
                        <source
                          src={activityData?.media?.videos[0].media_path}
                          type="video/mp4"
                        />
                      </video>
                      :
                      ''
                      }
                    </div>
                  </div>
                )}

                
               
                { (activityData?.media?.quizs.length) && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}>
                    <h4>{t('Quiz')}</h4>
                    <div className="quiz mt-3">
                      <div
                        className="d-flex justify-content-between align-items-center mb-3"
                        >
                        <div className="d-flex justify-content-between align-items-center"
                        onClick={() => {
                          if (!moment(daySelected).isAfter(today)) {
                            if (activityData?.media?.quizs[0].is_answered !== 1)
                              navigate(`/tracking-system-quiz`, {
                                state: {
                                  questions: activityData?.media?.quizs[0].data,
                                  mediaId: activityData?.media?.quizs[0].id,
                                  userActivityId:
                                    activityData?.user_activity_id,
                                    
                                  percentage: activityData?.media?.quizs[0].percentage,  
                                },
                              });
                            else toast.error('Already Answered');
                          } else {
                            toast.error("Can't open today");
                          }
                        }}
                        >
                          
                          <FaRegEdit 
                           className="activity-icon"
                          style={{
                            color: activityData?.media?.quizs[0].percentage != 0 ? colors.chamThirdColor : colors.blue ,
                            background: activityData?.media?.quizs[0].percentage != 0 ? colors.lightred : colors.whiteBlue,
                            fontSize:'50px',
                            borderRadius: '10px',
                            padding: '10px',
                           
                          }}
                          />
                          <div>
                            <span>{t('Quiz Title')}</span>
                            <p className="m-0" style={{color: '#b3b3b3'}}>
                              {t('Quiz')},{' '}
                              {activityData?.media?.quizs[0].data.questions.length}{' '}
                              {t('Questions')}
                            </p>
                            { activityData?.media?.quizs[0].submitted_at && <div className="secondry-color font-weight-bold submitted-at">Submitted At: {activityData?.media?.quizs[0].submitted_at} </div>}
                          </div>
                        </div>

                        <div className='d-flex align-items-center'>
                        {activityData?.media?.quizs[0].is_answered == 1 ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {activityData?.media?.quizs[0].percentage == 0 && ( <ZeroProgressPopover /> ) }

                        </div>
                        

                        
                      </div>
                    </div>
                  </div>
                )}
                { (activityData?.media?.quizs.id) && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}>
                    <h4>{t('Quiz')}</h4>
                    <div className="quiz mt-3">
                      <div
                        className="d-flex justify-content-between align-items-center mb-3"
                        >
                        <div className="d-flex justify-content-between align-items-center"
                        onClick={() => {
                          if (!moment(daySelected).isAfter(today)) {
                            if (activityData?.media?.quizs.is_answered !== 1)
                              navigate(`/tracking-system-quiz`, {
                                state: {
                                  questions: activityData?.media?.quizs.data,
                                  mediaId: activityData?.media?.quizs.id,
                                  userActivityId:
                                    activityData?.user_activity_id,
                                    
                                  percentage: activityData?.media?.quizs.percentage,  
                                },
                              });
                            else toast.error('Already Answered');
                          } else {
                            toast.error("Can't open today");
                          }
                        }}
                        >
                          
                          <FaRegEdit 
                           className="activity-icon"
                          style={{
                            color: activityData?.media?.quizs.percentage != 0 ? colors.chamThirdColor : colors.blue ,
                            background: activityData?.media?.quizs.percentage != 0 ? colors.lightred : colors.whiteBlue,
                            fontSize:'50px',
                            borderRadius: '10px',
                            padding: '10px',
                           
                          }}
                          />
                          <div>
                            <span>{t('Quiz Title')}</span>
                            <p className="m-0" style={{color: '#b3b3b3'}}>
                              {t('Quiz')},{' '}
                              {activityData?.media?.quizs.data.questions.length}{' '}
                              {t('Questions')}
                            </p>
                            { activityData?.media?.quizs.submitted_at && <div className="secondry-color font-weight-bold submitted-at">Submitted At: {activityData?.media?.quizs.submitted_at} </div>}
                          </div>
                        </div>

                        <div className='d-flex align-items-center'>
                        {activityData?.media?.quizs.is_answered == 1 ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {activityData?.media?.quizs.percentage == 0 && ( <ZeroProgressPopover /> ) }

                        </div>
                        

                        
                      </div>
                    </div>
                  </div>
                )}
                {activityData?.media?.game.length != 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}>
                    <h4>{t('Game')}</h4>
                    {activityData?.media?.game.map((a: any) => {
                        return (
                     
                    <div
                      className="media mt-3"
                      onClick={() => {
                       
                        if (moment(daySelected).isSame(today)) {
                          setShowGame(true);
                          setActiveGameId(a.id)
                          setActiveGameMedia(a.media_path)
                        }else if (moment(daySelected).isBefore(today)) {
                          setshowGameEndMsg(true);
                        }
                        else {
                          toast.error("Can't open today");
                        }
                      }}>
                      <div
                        className={`d-flex justify-content-between align-items-center mb-3`}>
                        <div className='d-flex align-items-center'>
                        <IoGameControllerOutline 
                           className="activity-icon"
                            style={{
                              color:a.percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:a.percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '10px',
                             
                            }}
                            />
                            <div>
                              <span>{t('Game')}</span>
                              { a.submitted_at && <div className="secondry-color font-weight-bold submitted-at">Submitted At: {a.submitted_at} </div>}
                            </div>
                        </div>

                        <div className='d-flex align-items-center'>     
                        {a.is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {a.percentage == 0 && ( <ZeroProgressPopover /> ) }
                        </div>
                      </div>
                    </div>
                     );
                    })}
                  </div>
                )}

                {activityData?.media?.social_media.length != 0 && (
                  <div className="mb-5" style={{ cursor: 'pointer' }}
                 
                  >
                    <h4>{t('YouTube')}</h4>
                    {activityData?.media?.social_media.map((a: any) => {
                      return (
                    <div className="media mt-3"
                    onClick={() => {
                      if (!moment(daySelected).isAfter(today)) {
                        setActiveSocialId(a.id)
                        setActiveSocialMedia(a.media_path)
                        setShowYoutubeTask(true);
                      } else {
                        toast.error("Can't open today");
                      }
                    }}
                    >
                      <div
                        className={`d-flex justify-content-between align-items-center mb-3`}>
                        <div className='d-flex align-items-center'>
                        <FaYoutube
                             className="activity-icon"
                            style={{
                              color:a.percentage != 0 ? colors.chamThirdColor : colors.blue ,
                              background:a.percentage != 0 ? colors.lightred : colors.whiteBlue,
                              fontSize:'50px',
                              borderRadius: '10px',
                              padding: '10px',
                             
                            }}
                            />
                          <div>
                          <span>{a.name}</span>
                          { a.submitted_at && <div className="secondry-color font-weight-bold submitted-at">Submitted At: {a.submitted_at} </div>}
                          </div>
                        </div>

                        <div className='d-flex align-items-center'>
                        {a.is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {a.percentage == 0 && ( <ZeroProgressPopover /> ) }
                        </div>
                      </div>
                    </div>
                    );
                  })}
                  </div>
                )}

                      
                {activityData?.media?.profs.length != 0 && (
                  <div className="mb-3" style={{ cursor: 'pointer' }}
                  onClick={ () => {
                    if( moment(daySelected).isAfter(today) ){
                      toast.error("Can't open today");
                    }
                  } }
                  >
                    <h4 className='mb-4'>{t('Proof')}</h4>
                    {activityData?.media?.profs.map((a: any,index:any) => {
                      {/*proof*/}
                      return (
                    
                       <ProofItem 
                       daySelected={daySelected}
                       today={today}
                       hasHistory={hasHistory}
                       proof={a}
                       index={index}
                       user_activity_id={activityData.user_activity_id}
                       actId={actId}
                       getData={ () => getData(1) }
                       dateType={activityData?.date_type}
                       trackType={trackType}
                       showMsgPopup={ () => { setShowDateRangeMsg(true) } }
                       
                       />
                      

                    );
                    {/*proof*/}
                  })}
                  </div>
                )}
              </div>
            ) : (
              ''
            )}

            <div>
              {bottomSmallAds.length > 0 ? (
                <div className="mt-3 mb-5 cursor-pointer">
                  {bottomSmallAds.map((item:any) => (
                     <div
                     onClick={ () => {
                      if(item.model_name == 'Course' ){
                       
                        window.location.href = `/courses/${item.model_id}`
                      }else if(item.model_name == 'Consultant'){
                        window.location.href = `/consultants/organization/${item.model_id}`
                      }
                      else if( item.model_name == 'Tracking' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/tracking/${item.model_id}/details`
                        }
                      }
                      else if( item.model_name == 'TrackingPackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/package/levels?discover=1`
                        }
                      }
                      else if( item.model_name == 'Initiative' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href = `/user/initiative/${item.model_id}/details/enrolled/${item.selected_enrolled_user_id}`
                        }else{
                          window.location.href = `/user/trackings/${item.model_id}/details?is_level=0`
                        }
                      }else if( item.model_name == 'InitiativePackage' ){
                        if( item.selected_enrolled_user_id ){
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=0`
                        }else{
                          window.location.href =`/user/initiatives/${item.model_id}/package/levels?discover=1`
                        }
                      }
                    } }
                     ><img src={item.image} alt="img" className='w-100 rounded' /></div>
                  ))}
                </div>
              ) : (
              ''
              )}
            </div>

            <div className="mb-5">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="m-0">{t('Notes')}</h4>
                <div
                 
                  style={{
                    color: '#21a6a6',
                    fontWeight: '500',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                    
                  }}
                  onClick={() => getAllNote()}>
                  {t('See all')}
                </div>
              </div>

              {activityData?.notes?.length > 0 && (
                <div>
                  {activityData?.notes.map((n: any) => {
                    return (
                      <div className="media mt-3">
                        <div className="d-flex align-items-center mb-3">
                          <img
                            src="/icons/file.png"
                            alt="image"
                            style={{width: '50px', margin: '0 20px 0 0'}}
                          />
                          <div className="w-100">
                            <span>{n.note}</span>
                            <div className="d-flex justify-content-between align-items-center">
                              <p
                                className="m-0 w-100"
                                style={{color: '#b3b3b3'}}>
                                {moment(n.note_added_date)
                                  .locale('en')
                                  .format('D MMM YYYY')}
                              </p>
                              <div className="d-flex align-items-end">
                                <p
                                  className="cursor-pointer"
                                  style={{color: '#0acad7', margin: '0 20px'}}
                                  onClick={() => {
                                    setShow(true);
                                    setSelectedNoteIdValue(n.note);
                                    setSelectedNoteId(n.id);
                                  }}>
                                  Edit
                                </p>
                                <p
                                  className="m-0 secondry-color cursor-pointer"
                                  onClick={() => {
                                    setConfirmDeleteNote(true);
                                    setSelectedNoteId(n.id);
                                  }}>
                                  Delete
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {!hasHistory && (

              <div>
                <p
                  className="cursor-pointer secondry-color mt-4"
                  style={{fontSize: '18px'}}
                  onClick={() => {
                    setShowAddNote(true);
                  }}>
                  + {t('Add new note')}
                </p>
              </div>
              ) }
            </div>

            {autoUpdate == 0 && daySelected == today ? (
              <div
                className="mb-5"
                onClick={() => {
                  setUserActivityId(activityData?.user_activity_id);
                  setShowUpdateProgress(true);
                }}>
                <MainButton text={'Update Progress'} />
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>

      {/*Edit Note*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Edit Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}>
              {selectedNoteValue}
            </textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => editNote()}>
            <MainButton text={'Edit note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Add Note*/}
      <Modal show={showAddNote} onHide={() => setShowAddNote(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Add Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}></textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() =>
              selectedNoteValue && addNote(activityData.user_activity_id)
            }>
            <MainButton text={'Add note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm delete note*/}
      <Modal
        show={confirmDeleteNote}
        onHide={() => setConfirmDeleteNote(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              Are you sure that you want to delete the selected note ?
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => deleteNote(selectedNoteId)}>
            <MainButton text={'Confirm'} />
          </div>
          <div onClick={() => setConfirmDeleteNote(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Note List*/}
      <Modal show={showAllNotes} onHide={() => setShowAllNotes(false)} centered>
        

        <Modal.Body>
        {allNote.length > 0 && (
          <div>
            {allNote.map((n: any) => {
              return (
                <div className="media mt-3">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src="/icons/file.png"
                      alt="image"
                      style={{width: '50px', margin: '0 20px 0 0'}}
                    />
                    <div className="w-100">
                      <span>{n.note}</span>
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          className="m-0 w-100"
                          style={{color: '#b3b3b3'}}>
                          {moment(n.note_added_date)
                            .locale('en')
                            .format('D MMM YYYY')}
                        </p>
                        <div className="d-flex align-items-end">
                          <p
                            className="cursor-pointer"
                            style={{color: '#0acad7', margin: '0 20px'}}
                            onClick={() => {
                              setShow(true);
                              setSelectedNoteIdValue(n.note);
                              setSelectedNoteId(n.id);
                            }}>
                            Edit
                          </p>
                          <p
                            className="m-0 secondry-color cursor-pointer"
                            onClick={() => {
                              setConfirmDeleteNote(true);
                              setSelectedNoteId(n.id);
                            }}>
                            Delete
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
         {allNote.length == 0 && (
          <h5 className='text-center'>There is no data found...</h5>
         )}
        </Modal.Body>

       
      </Modal>

      {/*Update Progress manually*/}
      <Modal
        show={showUpdateProgress}
        onHide={() => setShowUpdateProgress(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            onClick={handleClick}
            style={{
              width: '100%',
              height: '10px',
              backgroundColor: '#ccc',
              position: 'relative',
              borderRadius: '15px',
              cursor: 'pointer',
              marginTop: '25px',
            }}>
            <span
              className={classes.ProgressValue}
              style={{
                color: '#fff',
                background: '#21a6a6',
                borderBottom: '10px solid #21a6a6',
                left: `${styleProgress}%`,
              }}>
              {progress}%
            </span>
            <div
              className="progress"
              style={{
                width: `${progress}%`,
                height: '100%',
                backgroundColor: 'green',
                position: 'absolute',
              }}></div>
          </div>
          <div className="d-flex justify-content-between">
            <div>0%</div>
            <div>100%</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => updateManualProgress()}>
            <MainButton text={'Done'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Update Game*/}
      <Modal
        show={showGame}
        onHide={() => setShowGame(false)}
        centered
        size="lg">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <iframe
            title="Your iFrame Title"
            style={{width: '100%', height: '400px'}}
            src={
              activeGameMedia
            }
            frameBorder="0"
            allowFullScreen></iframe>
        </Modal.Body>

        <Modal.Footer>
          { moment(daySelected).isSame(today) && (
          <div
            className="w-100"
            onClick={() => {
              setConfirmUpdate(true);
            }}>
            <MainButton text={'Submit'} />
          </div>
          ) }
           { moment(daySelected).isBefore(today) && (
          <div
            className="w-100"
            onClick={() => {
              setShowGame(false)
            }}>
            <MainButton text={'Cancel'} />
          </div>
          ) }
        </Modal.Footer>
      </Modal>

      {/*Confirm update game*/}
      <Modal
        show={confirmUpdate}
        onHide={() => setConfirmUpdate(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="w-100 mb-3"
            onClick={() => {
              daySelected == today &&
                updateAuto(activeGameId, 100);
              setConfirmUpdate(false);
              setShowGame(false);
            }}>
            <MainButton text={'Continue With Saving'} />
          </div>
          <div
            onClick={() => {
              setConfirmUpdate(false);
              setShowGame(false);
            }}
            className="w-100">
            <CancelButton text={'Continue Without Saving'} />
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>

      {/*Update social*/}
      <Modal
        show={showYoutubeTask}
        onHide={() => setShowYoutubeTask(false)}
        centered
        size="lg">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {activityData?.media?.social_media.length ?
          <div className='social-popup'>
          <YouTube
            videoId={
              activeSocialMedia
            }
            opts={videoOptions}
            onEnd={() => {
              daySelected == today &&
                updateAuto(
                  activeSocialId,
                  100,
                );
                setShowYoutubeTask(false)
            }}
          />
          </div>
          :
          ''
           }

        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() => {
              setConfirmUpdate(true);
            }}>
           
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm prev game*/}
      <Modal
        show={showGameEndMsg}
        onHide={() => setshowGameEndMsg(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
            You can open this task but without getting the progress because the deadline has passed
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => {
            setshowGameEndMsg(false)
            setShowGame(true)

          }}>
            <MainButton text={'Confirm'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Show data range msg*/}
      <Modal
        show={showDateRangeMsg}
        onHide={() => setShowDateRangeMsg(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="text-center">
            <h5 className="w-100 mb-4 font-weight-bold">
            Upon completion of this activity, your score will be added to your total for the day ({activityData?.first_day}). You can view the activity in the “Activities” of this previous date.
            </h5>
          </div>

          <div
            className="w-100 mb-3"
            onClick={() => {
              setShowDateRangeMsg(false)
            }}>
            <MainButton text={'Ok'} />
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserActvitiyAddProgress;
